import * as React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <section
    style={{height: "70vh", marginTop: "10rem"}}
  >
    <SEO title="404: Not found" />
    <h1 style={{fontSize: "3rem"}}>404: Bulunamadı</h1>
    <p>Gitmek istediğiniz sayfa bulunamadı.</p>
  </section>
)

export default NotFoundPage
